@import '../variables';
.header {
    border-bottom: 1px solid $gray-line;
    padding: 40px 0;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__logo {
        display: flex;
        // border: 1px solid red;
        align-items: center;
        img {
            margin-right: 20px;
        }
        h1 {
            color: #181818;
            font-size: 24px;
            letter-spacing: 1%;
            text-transform: uppercase;
            font-weight: 800;
        }
        p {
            color: #7b7b7b;
            text-align: center;
        }
    }
}