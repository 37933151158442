@import '../variables';
.button {
    display: inline-block;
    background-color: $orange;
    border-radius: 12px;
    padding: 10px 20px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    transition: background-color $duration ease-in-out, border-color $duration ease-in-out;
    border: 1px solid transparent;
    @include noselect();
    &,
    span {
        color: #fff;
    }
    i,
    span,
    path,
    svg {
        transition: all $duration ease-in-out;
    }
    &:hover {
        background-color: darken($orange, 8%);
    }
    &:active {
        background-color: darken($orange, 12%);
        transform: translateY(1px);
    }
    &--circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        min-width: 32px;
        padding: 0;
        border-width: 2px;
    }
    &--black {
        background-color: $black;
        &:hover,
        &:active {
            background-color: lighten($color: $black, $amount: 10);
        }
    }
    &--outline {
        background-color: #fff;
        border-color: $orange;
        &,
        span {
            color: $orange;
        }
        svg {
            path {
                fill: $orange;
            }
        }
        &:hover {
            background-color: $orange;
            &,
            span {
                color: #fff;
            }
            svg {
                path {
                    fill: #fff;
                }
            }
        }
        &:active {
            background-color: darken($orange, 8%);
        }
    }
    &__delimiter {
        width: 1px;
        height: 25px;
        background-color: rgba(255, 255, 255, 0.25);
        margin-left: 14px;
        margin-right: 14px;
    }
    &--add {
        svg {
            margin-right: 2px;
        }
        span {
            font-weight: 600;
            font-size: 16px;
        }
        &:hover {
            i {
                background-color: #fff;
                color: $orange;
            }
        }
        i {
            display: inline-block;
            border-radius: 12px;
            background-color: $orange;
            color: #fff;
            font-weight: 600;
            width: 22px;
            height: 22px;
            font-style: normal;
            font-size: 13px;
            line-height: 22px;
            position: relative;
            top: -1px;
            left: 3px;
        }
    }
    &--cart {
        display: flex;
        align-items: center;
        line-height: 23px;
        padding: 12px 25px;
        svg {
            margin-right: 8px;
            margin-bottom: 1px;
        }
        span {
            font-weight: 600;
            font-size: 16px;
        }
    }
}