@import '../variables';
.pizza-block {
    width: 300px;
    text-align: center;
    margin-bottom: 65px;
    // margin-left: 5px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.67);
    &:not(:nth-of-type(4n)) {
        margin-right: 1.58%;
    }
    &__image {
        width: 250px;
        border-radius: 12px 12px 0px 0px;
        // border: 1px solid red;
    }
    &__title {
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0pt;
        margin-bottom: 1px;
        height: 40px;
    }
    &__selector {
        display: flex;
        // background-color: #f3f3f3;
        border-radius: 10px;
        flex-direction: column;
        padding: 1px;
        p {
            color: #8D8D8D;
            text-align: center;
            margin-left: 5px;
            margin-right: 5px;
        }
        ul {
            display: flex;
            flex: 1;
            &:first-of-type {
                margin-bottom: 6px;
            }
            li {
                padding: 8px;
                flex: 1;
                cursor: pointer;
                font-weight: 600;
                font-size: 14px;
                @include noselect();
                &.active {
                    background: #ffffff;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
                    border-radius: 5px;
                    cursor: auto;
                }
                &.disabled {
                    opacity: 0.2;
                    pointer-events: none;
                }
            }
        }
    }
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        padding-bottom: 15px;
        padding-right: 5px;
    }
    &__price {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.015em;
        padding-left: 20px;
    }
}