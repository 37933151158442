@import '../variables';
.categories {
    // background-color: #e74c3c;
    min-width: 200px;
    margin: 5px;
    overflow-x: auto;
    ul {
        display: flex;
        li {
            background-color: #f9f9f9;
            padding: 13px 30px;
            border-radius: 12px;
            // margin-right: 10px;
            font-weight: bold;
            font-size: .8rem;
            display: flex;
            margin: auto;
            text-align: center;
            cursor: pointer;
            transition: background-color 0.1s ease-in-out;
            @include noselect();
            &:hover {
                background-color: darken(#f9f9f9, 2%);
            }
            &:active {
                background-color: darken(#f9f9f9, 5%);
            }
            &.active {
                background-color: #f1eded;
                color: rgb(245, 177, 88);
            }
        }
    }
}

.categories::-webkit-scrollbar {
    display: none;
}